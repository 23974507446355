<template>

  <div id="motor-list">
    <div class="options w-100 py-2">


<div class="text-center mt-4 h5">

  {{$t('reg.account-waiting')}}



</div>
      <div class="text-center small mt-4">
        <a href="/" @click.prevent="logout()" :title="$t('menu.logout')">{{$t('menu.logout')}}  <i class="fas fa-sign-out-alt"></i></a>
      </div>

    </div>
  </div>
</template>

<script>

import axios from "axios";
import userMixin from "@/mixins/userMixin";



export default {
  name: "NonConfirmed.vue",
  mixins:[userMixin],
  data() {
    return {
      prevRoute: null
    }
  },
  beforeRouteEnter(to, from, next) {
    console.log('BE RO ', to, from)

    next(vm => {
      vm.prevRoute = from
      vm.recheck()
    })
  },
  mounted() {

  },
  methods:{
    recheck(){
      let token = localStorage.getItem('user-token') || ''
      const config = {
        headers: {
          'Authorization': `Bearer ${token}`
        }
      }

      axios.get(process.env.VUE_APP_API_ROOT_URL + "/user/me.json?t="+ new Date().getTime(), config).then(result => {
        //console.log('me', result.status, result.data)
        let proslo=false
        if (result.status == 200 && result.data.id > 0) {
          this.$store.commit('setuser', result.data)

          if (result.data.company_accepted_at!=null){
            proslo=true
            this.$router.push(this.prevRoute)
          }
        }

        if (!proslo) setTimeout(this.recheck, 2000)
      })


    }
  }
}
</script>

<style scoped>

</style>
